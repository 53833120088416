<template>
  <div>
  <v-card
    flat
    class="mt-10 ml-5 mr-5"
  >
    <v-card-title
      class="ml-3 mt-3 mb-0"
    >
      <div v-if="!paymentLinkModel.isDirect">
        <p>Hello <strong>{{ paymentLinkModel.customer.firstName + ' ' + paymentLinkModel.customer.firstName }}</strong>,</p>
        <p>Your payment request for: <strong>{{ formatCurrency(paymentLinkModel.amount) }} USD</strong></p>
      </div>
      <div v-else>
        <p><strong>{{ formatCurrency(paymentLinkModel.amount) }} USD</strong></p>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-img
          :src="config.app.fullLogo"
          max-height="50px"
          :alt="config.app.name"
          contain
        ></v-img>
      </div>
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="onSubmitHandler"
      >
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <v-text-field
                v-model="paymentLinkModel.customer.firstName"
                outlined
                dense
                :readonly="!paymentLinkModel.isDirect"
                :disabled="!paymentLinkModel.isDirect"
                placeholder="First Name"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>First Name
                </template>
              </v-text-field>

              <v-text-field
                v-model="paymentLinkModel.customer.lastName"
                :rules="[validators.required]"
                :readonly="!paymentLinkModel.isDirect"
                :disabled="!paymentLinkModel.isDirect"
                outlined
                dense
                placeholder="Last Name"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Last Name
                </template>
              </v-text-field>

              <v-text-field
                v-model="paymentLinkModel.customer.personalId"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="Personal ID"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Personal ID
                </template>
              </v-text-field>

              <v-text-field
                v-model="paymentLinkModel.customer.email"
                :rules="[validators.required, validators.emailValidator]"
                :readonly="!paymentLinkModel.isDirect"
                :disabled="!paymentLinkModel.isDirect"
                outlined
                dense
                type="email"
                placeholder="E-mail"
                hide-details="auto"
                class="mt-3"
              >
              <template #label>
                  <span class="red--text"><strong>* </strong></span>E-mail
                </template>
              </v-text-field>

              <v-text-field
                v-model="paymentLinkModel.customer.phone"
                :rules="[validators.required]"
                outlined
                dense
                type="phone"
                placeholder="Phone"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Phone
                </template>
              </v-text-field>

              <v-text-field
                v-model="paymentLinkModel.customer.address"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="Address"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Address
                </template>
              </v-text-field>

              <v-text-field
                v-model="paymentLinkModel.customer.city"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="City"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>City
                </template>
              </v-text-field>

              <v-text-field
                v-model="paymentLinkModel.customer.postcode"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="Postcode"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Postcode
                </template>
              </v-text-field>

              <v-select
                v-if="paymentLinkModel.isDirect"
                v-model="paymentLinkModel.countryCode"
                :loading="loading"
                :rules="[validators.required]"
                placeholder="Country"
                :items="countryFilterItems"
                item-text="text"
                item-value="value"
                outlined
                dense
                clearable
                hide-details="auto"
                class="mt-3"
                @change="onCountryChanged"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Country
                </template>
              </v-select>

              <v-text-field
                v-if="paymentLinkModel.isDirect"
                v-model="paymentLinkModel.amount"
                outlined
                dense
                :rules="[validators.required, validators.decimalValidator]"
                prefix="$"
                type="number"
                placeholder="Amount"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Amount
                </template>
              </v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="8"
            >
              <v-container>
                <h3 v-if="paymentLinkModel.countryCode" class="mb-5">
                  Please, click on the payment method of your preference
                </h3>
                <h3 v-else>
                  Select your country to show which payment solutions are available
                </h3>
                <v-row
                  v-for="channel in channels"
                  :key="channel.channelCode"
                  dense
                >
                  <v-col>
                    <h2>{{ channel.channelName }}</h2>
                    <v-row class="mt-5 mb-5">
                      <v-col
                        v-for="paymentMethod in channel.paymentMethods"
                        :key="paymentMethod.paymentMethodId"
                        v-model="paymentMethodModel"
                        :rules="[validators.required]"
                        class="d-flex child-flex"
                        md="3"
                      >
                        <v-card
                          hover
                          outlined
                          :loading="loading"
                          :class="paymentMethodModel == paymentMethod ? 'selected' : ''"
                          @click="onPaymentMethodSelectedHandler(paymentMethod)"
                        >
                          <v-img
                            :src="imagePath + '/' + paymentMethod.imageName"
                            class="white--text align-end"
                          ></v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
      </v-form>
    </v-card-text>
  </v-card>

    <div class="pa-3">
      <!-- action buttons -->
      <v-card-text class="text-center">
        <v-btn
          color="primary"
          type="submit"
          class="me-3 mt-3"
          x-large
          :disabled="!valid || paymentMethodModel === null"
          :loading="loading"
          @click="onPayClickedHandler"
        >
          Pay {{ formatCurrency(paymentLinkModel.amount) }} USD {{ paymentMethodModel != null ? `with: ${paymentMethodModel.legacyName}` : '' }}
        </v-btn>
      </v-card-text>
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar.isSnackbarVisible"
      top
      right
      multi-line
      :timeout="snackbar.snackbarTimeOut"
    >
      <span v-html="snackbar.snackbarMessage"></span>
      <template v-slot:action="{ attrs }">
        <v-icon
          size="20"
          @click="snackbar.isSnackbarVisible = false"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</div> 
</template>

<script>
import {
  onMounted, ref,
} from '@vue/composition-api'
import { mdiClose, mdiKeyOutline, mdiHelpCircleOutline } from '@mdi/js'
import {
  required,
  emailValidator,
  decimalValidator,
  numbersValidator,
} from '@core/utils/validation'
import axios from 'axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import {
  formatCurrency,
} from '@core/utils/filter'

export default {
  setup() {
    const imagePath = ref(process.env.VUE_APP_AWS_S3_PAYMENT_METHODS)
    const config = ref(themeConfig)
    const { route } = useRouter()
    const accessToken = ref('')
    const countryFilterItems = ref([])

    const snackbar = ref({
      isSnackbarVisible: false,
      snackBarColor: 'info',
      snackbarMessage: '',
      snackbarTimeOut: -1,
    })

    const paymentLinkModel = ref({
      shopId: null,
      customer: {},
      paymentMethod: {},
      countryCode: null,
    })

    const channels = ref([])
    const paymentMethodModel = ref(null)
    const loading = ref(false)
    const valid = ref(false)
    const form = ref()
    const onSubmitHandler = () => {

    }

    const showSnackbarMessage = data => {
      let msg = data.message
      if (data.type === 'error') {
        msg = '<p class="red--text">ERROR:</p>'
        data.message.forEach(x => {
          msg += `<li>${x.message}</li>`
        })
      }
      snackbar.value.isSnackbarVisible = true
      snackbar.value.snackBarColor = data.type
      snackbar.value.snackbarMessage = msg
    }

    const axiosIns = axios.create({
      baseURL: process.env.VUE_APP_GATEWAY_API_ENDPOINT,
      headers: { 'content-Type': 'application/json', 'cache-control': 'no-cache', authorization: `Bearer ${accessToken}` },
    })

    const fetchPaymentLink = () => {
      loading.value = true

      axiosIns
        .get("/processing/payment-links", {
          params: { token: route.value.params.token },
        })
        .then((response) => {
          paymentLinkModel.value = response.data
          return response
        })
        .then((linkResponse) => {
          if (linkResponse.data.isDirect){
            axiosIns.defaults.headers.authorization = `Bearer ${linkResponse.data.token}`
            axiosIns.get(
              "/processing/page-setups/minimal-payment-links/add/available-countries",
              { params: { shopId: linkResponse.data.shopId } }
            ).then(pageSetupResponse => {
              countryFilterItems.value = pageSetupResponse.data
            })
          }else{
            onCountryChanged()
          }
        })
        .catch((error) => {
          showSnackbarMessage({ type: "error", message: error.response.data })
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onCountryChanged = () => {

      if (paymentLinkModel.value.countryCode == null){
        channels.value = null
        return
      }

      loading.value = true

      axiosIns.defaults.headers.authorization = `Bearer ${paymentLinkModel.value.token}`
      axiosIns
        .get("/processing/payment-links/payment-methods", {
          params: {
            shopId: paymentLinkModel.value.shopId,
            countryCode: paymentLinkModel.value.countryCode,
          },
        })
        .then((pm) => {
          channels.value = pm.data.channels
        }).catch(error => {
          console.log(error.response)
        }).finally(() => {
          loading.value = false
        })
    }

    const onPaymentMethodSelectedHandler = paymentMethod => {
      paymentMethodModel.value = paymentMethod
    }

    const onPayClickedHandler = () => {
      loading.value = true

      paymentLinkModel.value.paymentMethod = paymentMethodModel.value

      axiosIns.post(`/processing/payment-links/${paymentLinkModel.value.paymentLinkCode}/create-payment`, paymentLinkModel.value)
        .then(response => {
          showSnackbarMessage({ type: 'info', message: `Payment Created. <a href='${response.data}'>OPEN CHECKOUT</a>` })
          window.location.href = response.data
        })
        .catch(error => {
          showSnackbarMessage({ type: 'error', message: error.response.data.errors })
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchPaymentLink()
    })

    return {
      loading,
      form,
      valid,
      snackbar,
      paymentLinkModel,
      paymentMethodModel,
      countryFilterItems,
      channels,
      imagePath,
      config,
      showSnackbarMessage,
      formatCurrency,
      fetchPaymentLink,
      onSubmitHandler,
      onPaymentMethodSelectedHandler,
      onPayClickedHandler,
      onCountryChanged,

      // validation
      validators: { required, emailValidator, decimalValidator, numbersValidator },
      icons: {
        mdiClose,
        mdiKeyOutline,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

  <style lang="scss" scoped>
  .two-factor-auth {
    max-width: 25rem;
  }

  .security-character {
    position: absolute;
    bottom: -0.5rem;
  }

  .red--text{
    color: red;
  }

  .selected {
    border: 2px solid;
  }
  </style>
